import React from "react";

export default function Filter({ FilterOption, setFilterType, filterType }) {
  return (
    <div>
      <div>
        <select
          style={{ textAlign: "start", fontWeight: "bold" }}
          class="d-flex justify-content-start text-secondary border border-secondary btn btn-sm"
          onChange={(e) => {
            setFilterType(e.target.value);
          }}
          value={filterType}
        >
          {FilterOption.map((item) => (
            <option className="filter-options" value={item.value} key={item}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
