export const MenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-networking"></i>,
    to: "/dashboard",
  },
  {
    title: "Mentor Management",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-user-9"></i>,
    to: "/mentor-management",
  },
  {
    title: "Mentee Management",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-user-9"></i>,
    to: "/mentee-management",
  },
  {
    title: "Bookings Management",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-list-1"></i>,
    to: "/booking-management",
  },
  // {
  //   title: "Payment Management",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="flaticon-381-notebook-1"></i>,
  //   to: "/payment-management",
  // },
  {
    title: "Static Pages Content",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-list"></i>,
    to: "/static-pages",
  },
  {
    title: "FAQs Management",
    classsChange: "mm-collapse",
    iconStyle: <i className="flaticon-381-menu"></i>,
    to: "/faqs-list",
  },

  // {
  //     title: 'Dashboard',
  //     classsChange: 'mm-collapse',
  //     iconStyle: <i className="flaticon-381-networking"></i>,
  //     content: [
  //         {
  //             title: 'Dashboard',
  //             to: '/dashboard',
  //         },
  //         // {
  //         //     title: 'Analytics',
  //         //     to: '/analytics',
  //         // },
  //         // {
  //         //     title: 'Reviews',
  //         //     to: '/reviews',
  //         // },
  //         // {
  //         //     title: 'Order',
  //         //     to: '/order',
  //         // },
  //         // {
  //         //     title: 'Order List',
  //         //     to: '/order-list',

  //         // },
  //         // {
  //         //     title: 'General Customers',
  //         //     to: '/general-customers',
  //         // },
  // 		// {
  //         //     title: 'Task',
  //         //     to: '/task',
  //         // },
  //     ],
  // },
];
