import React, { useState, useContext } from "react";
/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";

//import EcomProductDetails from "./components/Dashboard/EcomProductDetails";
import Task from "./components/Dashboard/Task";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import UserList from "./pages/UserList";
import MenteeManagement from "./pages/MenteeManagement";
import StaticPages from "./pages/StaticPages";
import FAQsList from "./pages/FAQsList";
import BookingManagement from "./pages/BookingManagement";
import BookingDetails from "./pages/BookingDetails";
import PaymentManagement from "./pages/PaymentManagement";

//frontend end

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const allroutes = [
    /// Dashboard
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home /> },

    { url: "task", component: <Task /> },

    /// Form

    /// pages
    { url: "page-register", component: <Registration /> },
    { url: "page-lock-screen", component: <LockScreen /> },
    { url: "mentor-management", component: <UserList /> },
    { url: "mentee-management", component: <MenteeManagement /> },
    { url: "static-pages", component: <StaticPages /> },
    { url: "faqs-list", component: <FAQsList /> },
    { url: "booking-management", component: <BookingManagement /> },
    { url: "booking-details", component: <BookingDetails /> },
    { url: "payment-management", component: <PaymentManagement /> },
  ];

  return (
    <>
      <Routes>
        <Route path="page-lock-screen" element={<LockScreen />} />

        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
      </Routes>
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  //const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <>
      <div id="main-wrapper" className={`show`}>
        {/* <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle": ""} ${ menuToggle ? "menu-toggle" : ""}`}>   */}
        <Nav />
        <div
          className="content-body"
          style={{ minHeight: window.screen.height - 45 }}
        >
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
function FrontLayout() {
  return (
    <>
      <div className="overflow-unset">
        {/* <Layout /> */}
        <Outlet />
      </div>
    </>
  );
}

export default Markup;
