import React from "react";
import "../../pages/BookingDetails.css";
import moment from "moment";
import { Badge } from "react-bootstrap";
import starIcon from "../../../images/star.png";

const BookingInfo = ({ data }) => {
  return (
    <div className="booking-details">
      <div className="d-flex justify-content-between">
        <h3 className="mb-0">{data?.course?.subjectName}</h3>
        <p className="m-0">{moment(data?.createdAt).format("ll")}</p>
      </div>
      <p className="text-black m-0 pb-1" style={{ fontSize: "14px" }}>
        Ratings: <span>{data?.rating || "N/A"}</span>
        {data?.rating && (
          <img
            style={{ paddingRight: "6px", marginLeft: "5px" }}
            src={starIcon}
            width={20}
          />
        )}
      </p>
      {data.status === "Completed" ? (
        <Badge bg="success">Completed</Badge>
      ) : data.status === "Cancelled" ? (
        <Badge bg="danger light">Cancelled</Badge>
      ) : (
        <Badge bg="info light">Awaiting</Badge>
      )}

      <div className="details-section">
        <h4 className="font-w600">Course Details</h4>
        <div className="details">
          <p>
            <strong>Subject Name: </strong>
            {data?.course?.subjectName}
          </p>
          <p className="m-0">
            <strong>Rating:</strong> <span>{data?.rating || "N/A"}</span>
            {data?.rating && (
              <img
                style={{ paddingRight: "6px", marginLeft: "5px" }}
                src={starIcon}
                width={20}
              />
            )}
          </p>
          <p>
            <strong>Mentee:</strong>{" "}
            {data?.course?.menteeLearn?.map((item) => `${item},`)}
          </p>
          <p>
            <strong>Pricing:</strong>{" "}
          </p>
          <p>
            <strong>Description:</strong> {data?.course?.description}
          </p>
        </div>
      </div>
      <div className="details-section">
        <h4 className="font-w600">Booking Details</h4>
        <div className="details">
          <p>
            <strong>Amount: </strong>${data.amount}
          </p>
          <p>
            <strong>Booking Date:</strong>{" "}
            {moment(data?.bookingDate).format("ll")}
          </p>
          <p>
            <strong>Slot:</strong>{" "}
            {moment(data?.confirmSlot?.startTime).format("LT")} -{" "}
            {moment(data?.confirmSlot?.endTime).format("LT")}
          </p>
          <p>
            <strong>Meet url:</strong>{" "}
            <a
              href={data?.meetUrl}
              target="blank"
              style={{ textDecoration: "underline", color: "blue" }}
            >
              {data?.meetUrl || "N/A"}
            </a>
          </p>
        </div>
        <div className="details-section">
          <h4 className="font-w600">Payment Details</h4>
          <div className="details">
            <p>
              <strong>Amount: </strong>${data.payment[0].amount}
            </p>
            <p>
              <strong>Payout amount:</strong> ${data.payment[0].payoutAmount}
            </p>
            <p>
              <strong>Type:</strong> {data?.payment[0]?.type}
            </p>
            <p>
              <strong>Status:</strong> {data?.payment[0]?.status}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingInfo;
