import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Dropdown, Table } from "react-bootstrap";
import Pagination from "../components/Common/Pagination";
import PageTitle from "../layouts/PageTitle";
import toast from "react-hot-toast";
import { getBookingsApi } from "../../services/User/UserService";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Common/Spinner";
import Filter from "../components/Filter";

export default function BookingManagement() {
  const [loader, setLoader] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterType, setFilterType] = useState("past");

  const limit = 10;
  const navigate = useNavigate();
  const FilterOption = [
    { label: "All", value: "all" },
    { label: "Pending", value: "pending" },
    { label: "Up Coming", value: "upComing" },
    { label: "No Answered", value: "noAnswered" },
    { label: "Past", value: "past" },
  ];

  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getBookingsApi(currentPage, limit, filterType);
      const total = response.data.data.total;
      setPageCount(Math.ceil(total / limit));
      setBookingList(response.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [filterType, currentPage]);

  return (
    <div>
      <PageTitle activeMenu="Lists" motherMenu="Booking Management" />
      <div className="pb-4 d-flex justify-content-end">
        <Filter
          setFilterType={setFilterType}
          FilterOption={FilterOption}
          filterType={filterType}
        />
      </div>
      <Col>
        <Card>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Table responsive className="mostly-customized-scrollba">
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>BookingId</strong>
                    </th>
                    <th className="text-center">
                      <strong>Slot</strong>
                    </th>
                    <th className="text-center">
                      <strong>Amount</strong>
                    </th>
                    <th className="text-center">
                      <strong> STATUS</strong>
                    </th>
                    <th className="text-center">
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bookingList?.bookings?.map((item, i) => (
                    <tr key={i}>
                      <td>{item?._id}</td>
                      <td className="text-center">
                        {moment(item?.confirmSlot?.startTime).format("LT")}-
                        {moment(item?.confirmSlot?.endTime).format("LT")}
                      </td>
                      <td className="text-center">${item?.amount}</td>
                      <td className="text-center">
                        {item.status === "Completed" ? (
                          <Badge bg="success">Completed</Badge>
                        ) : item.status === "Cancelled" ? (
                          <Badge bg="danger light">Cancelled</Badge>
                        ) : (
                          <Badge bg="info light">Awaiting</Badge>
                        )}
                      </td>
                      <td className="text-center">
                        <div
                          className="btn btn-xs sharp"
                          style={{ backgroundColor: "#2f4cdd", color: "#fff" }}
                          onClick={() =>
                            navigate("/booking-details", { state: item })
                          }
                        >
                          <i className="fa fa-eye"></i>
                        </div>
                      </td>

                      {/* <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="warning light"
                            className="light sharp btn btn-warning i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {item.isBlocked ? (
                              <Dropdown.Item onClick={() => onAction(item._id)}>
                                Enable
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item onClick={() => onAction(item._id)}>
                                Disable
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {bookingList?.total === 0 && !loader && (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            )}
            {bookingList?.total === 0 ? (
              ""
            ) : (
              <div className="">
                <Pagination
                  limit={limit}
                  listLength={bookingList.total}
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
