import React from "react";
import "../../pages/BookingDetails.css";
import defaultImage from "../../../images/1.jpg";
import S3Image from "../S3Image";

const ProfileCard = ({ data, title }) => {
  const onImageError = (e) => {
    e.target.src = defaultImage;
  };
  return (
    // <div className="col-xl-3 col-xxl-3 col-lg-12 col-md-12">
    //       <div className="row">
    <div className="col-xl-12 col-lg-6 ">
      <div className="card h-auto">
        <div className="card-body text-center">
          <p className="text-black font-w550">{title}</p>
          {/* <img
            src={`https://staging-api.huddlelink.io/${data?.image}`}
            width={150}
            height={150}
            className="rounded-circle mb-4 booking-profile-img"
            alt="avatar1"
            onError={onImageError}
          /> */}
          <S3Image
            imageKey={data?.image}
            style={"rounded-circle mb-4 booking-profile-img"}
          />
          <h4 className="m-0 text-black font-w600">
            {data?.firstName} {data?.lastName}
          </h4>
          <small>{data?.email}</small>
          <br />
          <small>
            {data?.countryCode} {data?.phoneNumber}
          </small>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
